import React from "react"
import "./About.scss"

import imageFounder from "../copy/img/avatar/pic_1.jpg"
import { Link } from "gatsby"
import { siteRoutes } from "./../utils/siteRoutes"
import { scrollTo } from "../utils/utils"

function About(props) {
  return (
    <section
      id="about"
      className={`about section features-area overflow-hidden  ptb_25 ${
        props.page === "about" && "about-page"
      }`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2>DIY coded by actual Shopify Experts</h2>
              <p className=" d-sm-block mt-4">
                A little bit about the story behind EZFY.
              </p>
            </div>
          </div>
        </div>
        <div className="row about-image-with-text">
          <div className="col-12 col-md-6">
            <figure
              className="about-image wow fadeInLeft"
              data-aos-duration="2s"
              data-wow-delay="0.2s"
            >
              {/* Work Slider */}
              <img
                data-src={imageFounder}
                className="lazyload"
                alt="Diego Moretti- EZFY's creator"
              />
            </figure>
          </div>
          <div className="col-12 col-md-6 pt-4 pt-md-0">
            <ul className="features-item">
              <li>
                {/* Image Box */}
                <div
                  className="image-box media icon-1 px-1 py-3 py-md-4 wow fadeInRight"
                  data-aos-duration="2s"
                  data-wow-delay="0.2s"
                >
                  <div className="about-text icon-text media-body align-self-center align-self-md-start">
                    <p className="d-sm-block">
                      Greetings! My name is Diego. I am a Computer Science
                      graduate and <u>certified</u> Shopify Expert working as a
                      programmer since 2012.
                    </p>

                    <p className="d-sm-block  mt-4">
                      After working with a few Shopify merchants in the
                      beginning of my career, I noticed a lot of DIY happening.
                      Although it was helpful, the main issue was that most of
                      that DIY code laying around was not properly coded,
                      causing bugs, unmet expectations and making their store's
                      slower.
                    </p>

                    <p className="d-block mt-4">
                      Bearing that in mind, EZFY was born. I gathered a few{" "}
                      <b>like-minded talented professionals</b> and created{" "}
                      <span className="custom-link">EZFY</span> to help
                      merchants with their Shopify stores.
                    </p>
                    <p className="d-block mt-4">
                      Although we focus on
                      <Link to={siteRoutes.shop} className="custom-link">
                        copy/paste code snippets
                      </Link>{" "}
                      , we also offer top notch custom coding for whatever
                      technical needs you have.
                    </p>
                    <p className="d-block mt-4">
                      We also invite you to take a look at what our previous
                      customers testimonials, just{" "}
                      <Link to="/#reviews" className=" custom-link">
                        click here
                      </Link>
                      .
                    </p>
                    <p className="d-block mt-4">
                      Thank you for your time and for your visit!
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container about-help">
        <div className="row justify-content-center">
          <div className="col-12 ">
            {/* Section Heading */}
            <div className="section-heading text-center">
              <h2>How EZFY can help you</h2>
            </div>
          </div>
        </div>
        <div className="row about-buttons">
          <button
            onClick={() => {
              const $el = window.document.querySelector(`[id='CodeSnippets']`)

              if ($el) {
                scrollTo($el, -60)
              }
            }}
            className="btn  "
          >
            Code Snippets
          </button>
          <button
            onClick={() => {
              const $el = window.document.querySelector(`[id='custom-coding']`)

              if ($el) {
                scrollTo($el, -60)
              }
            }}
            className="btn btn--white"
          >
            Custom Coding
          </button>
        </div>
      </div>
    </section>
  )
}

export default About
